
import { inject } from '@/inversify';
import { $enum } from 'ts-enum-util';
import { Component, Mixins } from 'vue-property-decorator';
import type { AxiosError } from 'axios';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomDateRangePicker from '@/modules/common/components/ui-kit/custom-date-range-picker.vue';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DownloadReportForm, { DownloadReportControlMixin, FilterParam } from '@/modules/common/components/download-report-form.vue';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import Item from '@/modules/common/interfaces/item.interface';
import { FE_ONLY_PROVIDERS } from '@/modules/providers/constants';

import RatesFilterItemsMixin from '../mixins/rates-filters.mixin';
import { RatesDownloadExcelForm } from '../rates-api.service';
import PRICE_SHOWN from '../constants/price-shown.constant';
import { RatesAnalysisFiltersServiceS } from '../rates-analysis-filters.service';
import type RatesAnalysisFiltersService from '../rates-analysis-filters.service';

const DAY_RANGE_PRESETS = [30, 60, 90];
const FILTER_SELECT_ALL = (providerList: string[]) => providerList.filter(p => p !== 'cheapest');

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class RatesDownloadExcelPopup extends Mixins(RatesFilterItemsMixin, DownloadReportControlMixin) {
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    form: RatesDownloadExcelForm = {} as RatesDownloadExcelForm;
    private isLoading = false;
    private oldCompareTo = '';
    private oldProviderList: string[] = [];

    get attrs() {
        const { filters, customColumns } = this;
        const { properties, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            customColumns,
            properties,
            buttons,
            isLoading,
            dataType: this.isAnalysisMode ? this.$tc('titles.analysis') : this.$tc('titles.rates'),
        };
    }

    protected get isAnalysisMode() {
        return this.$route.name!.includes('.analysis');
    }

    private get isRangeValid() {
        const { monthrange } = this.form;

        if (!monthrange) return false;

        const maxRange = 365;
        const [start, end] = monthrange.map((date: string) => new Date(date));

        if (!start || !end) return false;

        const diff = Math.abs(start.getTime() - end.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        return diffDays <= maxRange;
    }

    private get isDownloadDisabled() {
        return !this.validateForm();
    }

    private get buttons() {
        const buttons = [
            {
                label: this.$tc('popup.download'),
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
        ];

        if (!this.isAnalysisMode) {
            buttons.push(
                {
                    label: this.$tc('popup.send'),
                    onClick: () => this.downloadExcel(true, false),
                    isDisabled: this.isDownloadDisabled,
                },
                {
                    label: this.$tc('popup.demandSend'),
                    onClick: () => this.downloadExcel(false, true),
                    isDisabled: this.isDownloadDisabled || !this.isScanAvailable,
                },
            );
        }

        return buttons;
    }

    private get isScanAvailable() {
        if (!this.compsetsService.currentCompset?.isActive) {
            return false;
        }

        return !(this.form.provider as string[]).some(p => this.providersService.allProviders[p]?.isScanDisabled);
    }

    private get properties() {
        return [
            {
                label: this.$tc('rates.datesCount'),
                key: 'daysCount',
                default: -1,
                component: CustomSelect,
                props: {
                    items: DAY_RANGE_PRESETS
                        .map(days => ({
                            name: this.$t('insights.nextDays', [days]).toString(),
                            value: days,
                        }))
                        .concat([{
                            name: this.$tc('rates.customRange'),
                            value: -1,
                        }]),
                },
                handlers: {
                    input: this.onDaysCountChange,
                },
            },
            {
                label: this.$tc('dateRange'),
                key: 'monthrange',
                component: CustomDateRangePicker,
                default: [],
                props: {
                    maxDays: 365,
                },
                handlers: {
                    input: this.onDateRangeChange,
                },
            },
            {
                label: this.$tc('titles.currency'),
                key: 'displayCurrency',
                component: CurrencySwitcher,
                default: this.settingsGeneralService.displayCurrency,
            },
        ];
    }

    private onDaysCountChange(value: any) {
        if (value === -1) {
            this.form.monthrange = [];
            return;
        }

        const startDate = new Date(new Date().toISOString().split('T')[0]);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + value);

        this.form.monthrange = [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]];
    }

    private onDateRangeChange(range: [string, string]) {
        const today = new Date();
        const start = new Date(range[0]);

        const isStartToday = start.getDate() === today.getDate()
            && start.getMonth() === today.getMonth()
            && start.getFullYear() === today.getFullYear();

        if (!isStartToday) {
            this.form.daysCount = -1;
            return;
        }

        const end = new Date(range[1]);
        const deltaDays = Math.ceil(Math.abs(start.getTime() - end.getTime()) / (1000 * 3600 * 24)) + 1;

        if (DAY_RANGE_PRESETS.includes(deltaDays)) {
            this.form.daysCount = deltaDays;
        } else {
            this.form.daysCount = -1;
        }
    }

    private get customColumns() {
        if (this.isAnalysisMode) return [];

        const isCheapestSelected = Array.isArray(this.form.provider)
            ? this.form.provider.includes('cheapest')
            : this.form.provider === 'cheapest';

        return [
            {
                key: 'market_demand',
                label: this.$tc('titles.marketDemand'),
                default: true,
            },
            {
                key: 'occupancy',
                label: this.$tc('titles.occupancy'),
                default: true,
            },
            {
                key: 'rank',
                label: this.$tc('titles.rank'),
                default: true,
            },
            {
                key: 'bookingBasic',
                label: this.$tc('titles.bookingBasic'),
                default: true,
            },
            {
                key: 'losResticted',
                label: this.$tc('titles.losRestriction'),
                default: true,
            },
            {
                key: 'multipleBestFlex',
                label: this.$tc('titles.multiplePrices'),
                default: true,
            },
            {
                key: 'diff_delta',
                label: `${this.$tc('titles.diff')} #`,
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'diff_precent',
                label: `${this.$tc('titles.diff')} %`,
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'median',
                label: this.$tc('compset.median'),
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'mealType',
                label: this.$tc('titles.mealType'),
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'roomType',
                label: this.$tc('titles.roomType'),
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
            {
                key: 'roomName',
                label: this.$tc('titles.roomName'),
                default: !isCheapestSelected,
                disabled: isCheapestSelected,
            },
        ];
    }

    private get compsetFilter() {
        const defaultCompset = this.compsetsService.currentCompset
            ? this.compsetsService.currentCompset.id
            : this.compsetTypeItems?.[0]?.value || null;

        return {
            label: this.$tc('titles.compset'),
            key: 'compsetId',
            options: this.compsetTypeItems,
            default: defaultCompset,
        };
    }

    private get providerFilter() {
        const { compsetId } = this.form;
        const { provider: globalProvider } = this.ratesService.settings;
        const provider = (this.form.provider || []);

        const EXCLUDE_PROVIDER = this.isAnalysisMode ? FE_ONLY_PROVIDERS : ['all'];

        const compset = this.compsetsService.getCompset(compsetId)
            || this.compsetsService.currentCompset;

        const providerItems = (compset?.rateProviders || [])
            .filter(p => !EXCLUDE_PROVIDER.includes(p));

        const defaultProvider = !EXCLUDE_PROVIDER.includes(globalProvider!)
            ? [globalProvider!]
            : [this.ratesService.settings.provider || providerItems[0]];

        const haveInvalidProviders = this.isAnalysisMode
            ? !providerItems.includes(provider as string)
            : (provider as string[]).some(p => !providerItems.includes(p));

        const providerDifference = !this.isAnalysisMode
            ? (provider as string[]).filter(p => !this.oldProviderList.includes(p))
            : [];

        const haveCheapestChannel = !this.isAnalysisMode
            ? providerDifference.includes('cheapest')
            : false;

        const options = this.providersService.toItemsList(providerItems);

        if (haveInvalidProviders) {
            this.form.provider = this.isAnalysisMode
                ? providerItems[0]
                : (provider as string[]).filter(p => providerItems.includes(p));
        }

        if (haveCheapestChannel) {
            this.disableNonCheapestColumns();
            this.form.provider = ['cheapest'];
        } else if (provider.includes('cheapest')) {
            this.form.provider = (provider as string[]).filter(p => p !== 'cheapest');
        }

        this.oldProviderList = [...provider];

        return {
            label: this.$tc('titles.provider'),
            key: 'provider',
            options,
            default: this.isAnalysisMode
                ? defaultProvider[0]
                : defaultProvider,
            multiselect: !this.isAnalysisMode,
            valuesOnly: true,
            filterSelectAll: FILTER_SELECT_ALL,
        };
    }

    private get posFilter() {
        return {
            label: this.$tc('titles.pos'),
            key: 'pos',
            options: this.posItems,
            default: this.posItems[0]
                ? this.pos || this.posItems[0].value
                : 'US',
        };
    }

    private get isShortFilter() {
        const { provider } = this.form;
        return provider ? provider.includes('google') : false;
    }

    private get priceTypeFilter() {
        const defaultPriceType = this.priceTypeItems
            .find(p => p.value === this.priceType) || this.priceTypeItems[0];

        return {
            label: this.$tc('titles.price'),
            key: 'priceType',
            options: this.priceTypeItems,
            default: this.isAnalysisMode
                ? defaultPriceType.value
                : [defaultPriceType],
            disabled: this.isShortFilter,
            multiselect: !this.isAnalysisMode,
        };
    }

    private get losFilter() {
        const { los } = this.documentFiltersService.settings;
        const values = this.form;

        const compset = this.compsetsService.getCompset(values.compsetId as string)
            || this.compsetsService.currentCompset;

        const choosenProviders = !this.isAnalysisMode
            ? (values.provider || []) as string[]
            : values.provider as string;
        const haveDisabledProviders = Array.isArray(choosenProviders)
            ? choosenProviders.some(p => this.providersService.allProviders[p]?.isScanDisabled)
            : choosenProviders === 'cheapest';

        const options = haveDisabledProviders
            ? compset!.los
            : DEFAULT_LOS;

        const defaultLos = options.includes(los!) ? los! : options[0];
        const choosenLos = (values.los || defaultLos) as number;

        if (!options.includes(choosenLos)) {
            values.los = defaultLos;
        }

        return {
            label: this.$tc('titles.los'),
            key: 'los',
            default: defaultLos,
            options: options.map(l => ({
                name: this.$t('filters.los.num', [l]).toString(),
                value: l,
            })),
        };
    }

    private get occupancyFilter() {
        const { provider } = this.form;
        const isNumberOfGuestDisabled = Array.isArray(provider)
            ? provider.some(p => this.providersService.allProviders[p]?.isScanDisabled) || false
            : this.providersService.allProviders[provider]?.isScanDisabled;

        return {
            label: this.$tc('titles.numberOfGuests'),
            key: 'numberOfGuests',
            options: this.numberOfGuestsItems,
            default: this.numberOfGuests || this.numberOfGuestsItems[0].value,
            disabled: this.isShortFilter || isNumberOfGuestDisabled,
        };
    }

    private get roomTypeFilter() {
        const { roomTypeId } = this.ratesFiltersService.settings;
        const roomTypeItems = this.roomTypeItems
            .filter(i => this.isAnalysisMode || i.value !== -1);

        const defaultRoomTypes = roomTypeId !== -1
            ? roomTypeItems.filter(i => i.value === roomTypeId)
            : [...roomTypeItems];
        const defaultRoomType = defaultRoomTypes[0] && defaultRoomTypes[0].value;

        return {
            label: this.$tc('titles.roomType'),
            key: 'roomTypeId',
            options: this.isAnalysisMode
                ? this.roomTypeItems
                : roomTypeItems,
            default: !this.isAnalysisMode
                ? defaultRoomTypes
                : defaultRoomType,
            disabled: this.isShortFilter,
            multiselect: !this.isAnalysisMode,
        };
    }

    private get mealTypeFilter() {
        const { mealTypeId } = this.ratesFiltersService.settings;
        const mealTypeItems = this.mealTypesService.mealTypes
            .filter(i => this.isAnalysisMode || i.id !== -1)
            .map(item => ({
                id: item.id,
                name: this.$tc(item.displayName),
                value: item.name,
            }));

        const defaultMealTypes = mealTypeId !== -1
            ? mealTypeItems.filter(i => i.id === mealTypeId)
            : [...mealTypeItems];
        const defaultMealType = defaultMealTypes[0] && defaultMealTypes[0].value;

        return {
            label: this.$tc('titles.mealType'),
            key: 'mealTypeId',
            options: mealTypeItems,
            default: !this.isAnalysisMode
                ? defaultMealTypes
                : defaultMealType,
            disabled: this.isShortFilter,
            multiselect: !this.isAnalysisMode,
        };
    }

    private get priceShownFilter() {
        const priceShownItems = $enum(PRICE_SHOWN)
            .getValues()
            .filter(e => e !== 'Calculated')
            .map(value => ({ value, name: this.$tc(`filters.price.${value.toLowerCase()}`) }));

        const { priceShown } = this.documentFiltersService;

        return {
            label: this.$tc('titles.priceShown'),
            key: 'priceShown',
            default: priceShown || PRICE_SHOWN.SHOWN,
            options: priceShownItems,
        };
    }

    private get filters() {
        const filters = [
            this.compsetFilter,
            this.providerFilter,
            this.posFilter,
            this.priceTypeFilter,
            this.losFilter,
            this.occupancyFilter,
            this.roomTypeFilter,
            this.mealTypeFilter,
            this.priceShownFilter,
        ];

        if (this.isAnalysisMode) {
            this.updateAnalysisFilters(filters as FilterParam[]);
        }

        return filters;
    }

    private updateAnalysisFilters(filters: FilterParam[]) {
        const { comparsionTypeItems } = this;
        const { comparisonValues: mainCompareValues, comparisonKey } = this.ratesAnalysisFiltersService;
        let { compareValue } = this.form;
        const oldCompareValue = JSON.stringify(compareValue);

        const defaultCompareFilter = comparisonKey || 'diffDays';
        const compareTo = (this.form.compareTo! || defaultCompareFilter) as keyof RatesDownloadExcelForm;
        const isCompareKeyChanged = this.oldCompareTo !== compareTo;

        let mainValue = this.form[compareTo] as string | number;

        const defaultCompareValue = mainCompareValues
            ? mainCompareValues.map(i => i.value)
            : [1];

        if (compareTo === 'mealTypeId') {
            mainValue = this.mealTypesService.getMealType(mainValue)?.id!;
        }

        let compareItems = [] as Item[];

        if (compareTo !== 'provider') {
            compareItems = this.ratesAnalysisFiltersService
                .getFilterItemsExceptValue(compareTo, mainValue);
        } else {
            const compset = this.compsetsService.getCompset(this.form.compsetId!)
                || this.compsetsService.currentCompset;

            compareItems = this.providersService
                .toItemsList((compset?.rateProviders || [])
                    .filter(p => p !== mainValue && !FE_ONLY_PROVIDERS.includes(p)));
        }

        if (compareValue) {
            compareValue = !isCompareKeyChanged
                ? compareValue.filter(i => i !== mainValue)
                : compareItems.slice(0, 2).map(i => i.value);
        } else {
            compareValue = defaultCompareValue;
        }

        compareValue = compareValue
            .filter(i => compareItems.some(c => c.value === i));

        const isCompareValueChanged = JSON.stringify(compareValue) !== oldCompareValue;

        this.oldCompareTo = compareTo;

        if (isCompareKeyChanged || isCompareValueChanged) {
            this.form.compareValue = compareValue;
            this.form.compareTo = compareTo;
        }

        filters.push(
            { divider: true, count: 6 } as any,
            {
                label: this.$tc('titles.compareto'),
                key: 'compareTo',
                options: comparsionTypeItems,
                default: defaultCompareFilter,
            },
            {
                label: this.$tc('titles.compareValue'),
                key: 'compareValue',
                options: compareItems,
                default: compareValue,
                maxSelected: 2,
                multiselect: true,
                valuesOnly: true,
            },
        );
    }

    private get comparsionTypeItems() {
        const compareFilter = this.ratesAnalysisFiltersService.filterList;
        const { compareTo } = this.form;
        const provider = this.form.provider as string;

        if (provider === 'cheapest') {
            if (compareTo === 'diffDays') {
                this.form.compareTo = 'provider';
            }

            return compareFilter
                .filter(item => item.value !== 'diffDays');
        }

        return compareFilter;
    }

    private getPreparedForm() {
        if (this.isAnalysisMode) {
            // NOTE: Since for compare mode we use <CustomSelect /> component
            //       that returns values directly instead of Item[] like <CustomMultiSelect />
            //       so we must convert back these values to Item[] type.
            const { roomTypeId, mealTypeId, priceType } = this.form as unknown as {
                roomTypeId: number;
                mealTypeId: string;
                priceType: string;
            };

            const roomTypeName = this.roomTypeItems.find(i => i.value === roomTypeId)!.name;

            const roomTypeParams = roomTypeId === -1
                ? this.roomTypeItems.filter(i => i.value !== -1)
                : [{ name: roomTypeName, value: roomTypeId }];
            const mealTypeParams = mealTypeId === 'any'
                ? this.mealTypesService.mealTypes
                    .filter(i => i.id !== -1)
                    .map(i => ({ value: i.name, name: i.displayName }))
                : [{ name: mealTypeId, value: mealTypeId }];

            return {
                ...this.form,
                roomTypeId: roomTypeParams,
                mealTypeId: mealTypeParams,
                priceType: [{ name: priceType, value: priceType }],
                provider: this.isAnalysisMode
                    ? [this.form.provider]
                    : this.form.provider,
            } as RatesDownloadExcelForm;
        }

        return {
            ...this.form,
            daysCount: undefined,
        };
    }

    // TODO Move all validatons into the request model
    private validateForm() {
        const { compareValue, monthrange } = this.form;
        const { pos, los, provider } = this.form;
        const { roomTypeId, mealTypeId, priceType } = this.form;

        if (compareValue && !compareValue.length) {
            this.triggerFormError(this.$tc('rates.err.selectValue'));
            return false;
        }

        if (monthrange && !monthrange.length) {
            this.triggerFormError(this.$tc('rates.err.selectRange'));
            return false;
        }

        if (!this.isRangeValid) {
            this.triggerFormError(this.$tc('rates.err.dateRange'));
            return false;
        }

        if (!pos) {
            this.triggerFormError(this.$tc('rates.err.selectPos'));
            return false;
        }

        if (!los) {
            this.triggerFormError(this.$tc('rates.err.selectLos'));
            return false;
        }

        if (!provider) {
            this.triggerFormError(this.$tc('rates.err.selectSource'));
            return false;
        }

        const isRoomTypeValid = typeof roomTypeId === 'number'
            ? !!roomTypeId
            : !!roomTypeId?.length;
        if (!isRoomTypeValid) {
            this.triggerFormError(this.$tc('rates.err.selectRoom'));
            return false;
        }

        if (!mealTypeId || !mealTypeId.length) {
            this.triggerFormError(this.$tc('rates.err.selectMeal'));
            return false;
        }

        if (!priceType || !priceType.length) {
            this.triggerFormError(this.$tc('rates.err.selectPrice'));
            return false;
        }

        return true;
    }

    private async downloadExcel(toEmail = false, onDemand = false) {
        try {
            this.isLoading = true;
            const form = this.getPreparedForm();

            await this.ratesService.getExcel(form, toEmail, onDemand);

            if (toEmail) {
                this.triggerFormMessage(this.$tc('popup.reportSent'));
            } else {
                this.closeForm();
            }
        } catch (e: unknown) {
            switch ((e as AxiosError).response?.status) {
                case 404:
                    this.triggerFormError(this.$tc('err.nodata'));
                    break;
                default:
                    this.triggerFormError(this.$tc('err.unknown'));
            }
        } finally {
            this.isLoading = false;
        }
    }

    private disableNonCheapestColumns() {
        [
            'diff_delta',
            'diff_precent',
            'median',
            'mealType',
            'roomType',
            'roomName',
        ].forEach(key => {
            if (!(key in this.form.columns)) return;
            this.form.columns[key as keyof RatesDownloadExcelForm['columns']] = false;
        });
    }
}
